h2.title {
  height: 4em;
  line-height: 3em;
  padding-left: 0.5em;
  font-size: 2em;
  text-align: left;
}

.fullSize {
  position: absolute;
  width: 100%;
  height: 100%;
}

p {
  margin: 0 15%;
}

body {
  font-size: 3vw;
  background-image: url(assets/background.jpg);
  background-size: cover;
  background-position: center;
  #background-color: #555;
  #background-blend-mode: multiply;
  width: 100vw;
  height: 100vh;
  color: #eee;
  text-align: center;
  text-shadow: 0px 0px 0.4em black;
}
body.desktop {
  font-size: min(3vw, 1.5vh);
}
#backgroundOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.8;
  z-index: -1;
}

.viewport {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.desktop .viewport {
  max-width: 80vh;
}

button {
  position: relative;
  color: #eee;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.9em;

  border: none
}
button img {
  width: 80%;
}

button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-image: url(assets/ButtonBitmap.png);
  border-style: solid;
  border-width: 1em;
  border-image-slice: 25% fill;
  z-index: -1;
}

.screenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding-top: calc(50vh - 8em);
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

.screenOverlay .loading {
  position: absolute;
  top: calc(50% - 5em);
  left: calc(50% - 5em);
  width: 10em;
  height: 10em;
}

.screenOverlay .closeButton {
  position: absolute;
  top: 1em;
  left: 1em;
  width: 4em;
  height: 4em;
  padding: 0.5em;
}

.screenOverlay .closeButton img {
  width: 80%
}


.screenOverlay button img {
  width: 8em;
  transition: all 2s;
}

img.animated {
  animation: spin 1s linear infinite;
  width: 1em;
}

button.pulsating {
  animation: pulse 1.2s linear infinite;
}


@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  50% {
    transform: translate(0, -50%) scale(1.2)
  }
  100% {
    transform: translate(0, -50%) scale(1)
  }
}

.playlistTrack {
  position: relative;
  transition: all 1s;
  left: 1em;
  width: calc(100% - 2em);
  height: 8em;
  text-align: center;
}

